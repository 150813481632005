.buttonGroup {
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  width: fit-content;
  margin: 0 auto;
  border-radius: 4px;
  overflow: hidden;
}
.button_radio {
  border: 1px solid #006b8f;
  padding: .5rem 1rem;
  text-align: center;
  font-size: 14px;

  &:first-child {
    border-radius: 0 4px 4px 0;
    margin-left: -1px;
  }
  &:last-child {
    border-radius: 4px 0 0 4px;
    border-right: none;
  }
}
.checked {
  background-color: #e6f5fa;
  color: #006b8f;
}
