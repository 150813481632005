@import '../school-card.module.scss';

.horizontal {
  &_bestmatch {
    margin-top: 0.25rem;
    color: #0099cc;
    font-size: 14px;
    font-weight: 800;
    margin-bottom: 10px;
    text-transform: uppercase;
  }
  &_school_name {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    gap: 0.5rem;
  }
  &_card_enrolling {
    @extend.card_bestmatch;
    background-color: #e6f5fa;
    color: #001f29cc;
  }
  &_school_info_content {
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
  }
}

.horizontalCard {
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #cedfe4;
  overflow: hidden;
  display: block;
  height: auto;

  @media (min-width: 767px) {
    grid-template-columns: 235px 1fr;
    display: grid;
  }
  @media (min-width: 980px) {
    height: 316px;
  }
}

.image_container {
  position: relative;
  transition: all 0.3s ease;
}

.buttonsContainer {
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (min-width: 760px) {
    justify-content: flex-start;
    flex-direction: row;
    margin: 0;
  }
}

.button {
  border-radius: 0.5rem;
  margin: 1rem 0;
  padding: 0.5rem 1.5rem;
  font-weight: 600;
  width: fit-content;
  text-align: center;
  cursor: pointer;
}

.viewProfileButton {
  @extend .button;
  border: 1px solid #9ebfc9;
  color: #006b8f;
}

.requestInfoButton {
  @extend .button;
  background: #006b8f;
  color: #fff !important;
}

.overview {
  font-size: 14px !important;
  color: #5f6a7d;
  margin-bottom: 0.5rem;
}

.image {
  overflow: hidden;
  height: 120px;
  display: flex;
  align-items: center;

  @media (min-width: 767px) {
    height: 100%;
  }

  img {
    object-fit: cover;
    width: 100%;
    min-height: 120px;
    height: 100%;
  }
}

.matchOverlay {
  background: #fff;
  position: absolute;
  top: 75px;
  bottom: 0;
  left: 0.5rem;
  right: 0.5rem;
  transition: 0.4s;
}
.school_address {
  line-height: 1.25;
}
