.school_info {
  font-size: 14px;
  color: #5f6a7d;
  text-transform: capitalize;
  margin: .5rem 0 1rem;
}
.location {
  margin-top: 8px;
  margin-bottom: 24px;

  display: inline-flex;
  align-items: center;
  gap: .75rem;
}

.miles {
  display: flex;
  align-items: center;
  gap: .25rem;
}
