.item {
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #cedfe4;
  overflow: hidden;
}

.card_image_container {
  position: relative;
}
.card_bestmatch {
  background-color: #fcf3d5;
  color: #ad6800;
  width: fit-content;
  padding: 0.5rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  gap: 0.3rem;
  font-weight: 600;
  align-items: center;
}
.card_favorite {
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  overflow: hiddent;
  position: absolute;
  z-index: 999;
  right: 12px;
  top: 12px;
  span {
    width: 22px;
    height: 22px;
    margin: auto;
  }
}
.card_image {
  // min-height: 120px;
  overflow: hidden;
  height: 100%;
  // max-height: 150px;
  display: flex;
  align-items: center;
  // @media screen and (min-width: 760px) {
  //   max-height: 239px;
  // }
  img {
    object-fit: cover;
    width: 100%;
    min-height: 120px;
    height: 100%;
  }
}
.vertical {
  &_card_image {
    @extend .card_image;
    height: 120px;
  }
}

.content {
  padding: 8px;
  position: relative;
}

.flex_content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 255px;
}

.school_name {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  height: 48px;

  h5 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .check_verified {
    padding-top: 7rem;
    color: #0099cc;
  }
}

.school_reviews_grades {
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.matchOverlay {
  background: #fff;
  position: absolute;
  top: 65px;
  bottom: 58px;
  left: 0.5rem;
  right: 0.5rem;
  transition: 0.4s;
}
