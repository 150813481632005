@mixin columns {
  width: 100%;
  display: grid;
  // gap: 14px;
}

.row {
  display: grid;
  gap: 20px;
  width: 100%;
  grid-template-columns: 1fr;
  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
}
.column {
  width: 100%;
  grid-template-columns: 1fr;
  background-color: #f3f5f7;
  border-radius: 8px;
  padding: 1rem;

  @media screen and (min-width: 525px) {
    padding: 20px 32px;
  }
}
.list_title {
  font-size: 24px;
  margin-bottom: 1rem;
}

@mixin bar {
  height: 8px;
  border-radius: 64px;
  width: 100%;
}

.range_level_container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 6px;
}

.barLevel {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.level_bar_base {
  @include bar;
}
.level_bar_dark {
  @include bar;
}
.level_dot {
  margin: 8px 0;
  width: 3px;
  height: 3px;
}
.level_title {
  font-size: 10px;
  text-align: center;
  line-height: 0;
}

.bgOn {
  background-color: #e6f8f1;
}
.fillOn {
  background-color: #02b674;
}
.colorOn {
  color: #02b674;
}

.bgOff {
  background-color: #fff1b8;
}
.fillOff {
  background-color: #e29f36;
}
.colorOff {
  color: #e29f36;
}

.skillList {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.skillTitle {
  display: flex;
  align-items: center;
  gap: .5rem;
  margin-bottom: 1rem;
}

.skillItem {
  width: 100%;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background: #fff;
}

.skillDescription {
  font-size: 12px;
  line-height: 24px;
}
