.gradesSection {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  margin-bottom: 1rem;

  @media (min-width: 525px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.gradesDrop {
  position: relative;
  text-transform: capitalize;
  font-size: 14px;

  & svg {
    fill: #818A99;
  }

  @media (min-width: 525px) {
    width: 200px;
  }
}

.buttonToggle {
  font-size: 14px;
  text-transform: capitalize;
  width: 100%;
  padding: .5rem 1rem;
  border-radius: 2px;
  border: 1px solid #C6CAD1;
  font-family: inherit;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #3C4A61;
}

.gradesList {
  border: 1px solid #C6CAD1;
  background: white;
  border-radius: 2px;

  position: absolute;
  top: 3rem;
  left: 0;
  right: 0;
  z-index: 1;
}

.item {
  display: flex;
  padding: .5rem 0;
  justify-content: space-between;
  align-items: center;
  padding: .75rem 1rem;

  &:hover {
    background: #E6F5FA;
  }
}