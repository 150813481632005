@mixin columns {
  width: 100%;
  display: grid;
  gap: 14px;
}

.row {
  display: grid;
  gap: 20px;
  width: 100%;
  grid-template-columns: 1fr;
  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.background {
  background-color: #f3f5f7;
  border-radius: 8px;
  padding: 1rem;

  @media screen and (min-width: 525px) {
    padding: 20px 32px;
  }
}

.list {
  @include columns;
  grid-template-columns: 1fr;
  .list_title {
    margin-bottom: 1rem;
    font-size: 24px;
  }
}
