.scholaShare {
  // display: flex;
  // flex-direction: column;
  // width: 393px;
  // height: 235px;
  border-radius: 8px;
  margin: auto;

  &__title {
    display: flex;
    justify-content: space-between;
    margin: 32px;
  }

  &__action {
    margin-top: 1em;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: 525px) {
      flex-direction: row;
      margin: 0 2rem 2rem;
    }
  }

  .norton-logo {
    display: none;
  }
}

.modalButton {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  width: fit-content;
  height: 66px;
  padding: 8px;
  font-size: 14px;
  text-align: center;
  color: #213556;

  &__text {
    margin-top: 10px;
  }
}

.saveStatus {
  left: 0;
  right: 0;
  color: #fff;
  font-size: 14px;
  height: 30px;
  text-align: center;
  font-weight: 600;
  padding: 8px;
  position: fixed;
  background-color: #99cc33;
  transform: translateY(-30px);
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.3);
  transition: all 0.1s ease-in-out;
  opacity: 0;
  z-index: 99999;
}

.saveStatus.show {
  top: 0;
  opacity: 1;
  transition-delay: 0.1s;
  transform: translateY(0);
}

.saveStatus.show.saved {
  transition-delay: 0.1s;
  background-color: #99cc33;
}

.saveStatus.show.failed {
  transition-delay: 0.1s;
  background-color: #f4364c;
}

.shareIconContainer {
  width: 50px;
  height: 50px;
  border: 1px solid #006b8f;
  border-radius: 80px;
  padding: 0.5rem;
}
